@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

/* font-family: 'Montserrat', sans-serif;
font-family: 'Poppins', sans-serif; */

:root {
  /* colors */
  --primary-bg: #ffffff;
  --secondary-bg: #0054a3;
  --light-grey: #63879e;
  --title-text-color: #000000;
  --text-color: #999999;
  --cooper-red: #e3000f;
  /* font sizes */
  --main-title-font-size: clamp(2.5rem, 3.5vw, 10vw);
  --title-font-size: clamp(2rem, 1.5vw, 6rem);
  --paragraph-font-size: clamp(1rem, 1.1rem + 0.1vw, 2.2vw);
  --button-font-size: clamp(1rem, 1.2vw, 4rem);
  --small-text-font-size: clamp(1rem, 0.8vw, 4rem);
  /* font styles */
  --title-font: 'Montserrat', sans-serif;
  --basic-font: 'Poppins', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

html {
  scroll-behavior: smooth;
  --scroll-behavior: smooth;
  overflow-x: hidden !important;
  max-height: 100%;
  height: -webkit-fill-available;
  font-family: var(--basic-font);
}

body {
  margin: 0;
  font-family: var(--basic-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  min-height: 100vh;
  background-color: var(--bg-primary);

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,
q {
  font-size: clamp(1rem, 2vw, 1.5rem);
}

h1 {
  font-size: clamp(2rem, 3vw, 2.5rem);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
q,
li {
  color: var(--title-text-color);
}

h1,
h2 {
  font-family: var(--title-font);
}

.bold {
  font-weight: 600;
}

#nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  top: 0;
  height: 135px;
  transition: all 0.3s;
  z-index: 1000;
  /* background-color: var(--secondary-bg); */
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 200px; /* Kesobb nem feltetlenul fog kelleni */
}

@media screen and (max-width: 1024px) {
  main {
    width: 100%;
    padding-top: 100px;
  }

  #nav {
    height: 100px;
  }
}

a {
  text-decoration: none;
}
